export const internetProviderMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'home',
    children: [],
  },
  {
    key: 'subIspManagement',
    title: 'Sub-ISP Management',
    route: '/main/isp/isp-main/sub-isp-management',
    icon: 'usergroup-add',
    children: [],
  },
  {
    key: 'internetPlans',
    title: 'Internet Plans ',
    route: '/main/isp/isp-main/internet-plans',
    icon: 'ie',
    children: [
      {
        key: 'domestic',
        title: 'Domestic',
        route: '/main/isp/isp-main/internet-plans/domestic',
        icon: '',
        children: [],
      },
      {
        key: 'corporate',
        title: 'Corporate',
        route: '/main/isp/isp-main/internet-plans/corporate',
        icon: '',
        children: [],
      },
    ],
  },
  {
    key: 'locationPlanMap',
    title: 'Location Plan Map',
    route: '/main/isp/isp-main/location-plan-map',
    icon: 'environment',
    children: [],
  },
  {
    key: 'connectionRequests',
    title: 'Connection Requests',
    route: '/main/isp/connection-request-management',
    icon: 'chrome',
    children: [],
  },
];
